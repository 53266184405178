.container {
    margin-bottom: 80px;
}

.title {
    text-align: left;
    font-size: 36px;
    margin: 0;
    margin-bottom: 8px;
}
