.playlist {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 0px 10px 20px 10px;
    border-radius: 8px;
}

.playlist:hover {
    cursor: pointer;
}

.title {
    height: 0;
    margin: 0;
    position: relative;
    top: 10px;
    left: 20px;
    color: white;
    z-index: 2;
}

.thumbnail_container {
    width: 100%;
    aspect-ratio: 2 / 1;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px black;
}

.thumbnail {
    width: 100%;
    transition: transform 0.2s;
}

.thumbnail:hover {
    transform: scale(1.1);
    cursor: pointer;
}