.navigationButton {
    width: 200px;
    height: 80px;
    color: white;
    font-size: 18px;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navigationButton:hover {
    letter-spacing: 3px;
    cursor: pointer;
}

.navigationButton_active {
    color: var(--text-highlight);
    font-weight: bold;
}

@media (max-width: 480px) {
    .navigationButton {
        width: 26%;
        font-size: 10px;
    }
}