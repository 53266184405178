.App {
    --dark-gray: #131313;
    --text: white;
    --text-highlight: turquoise;

    text-align: center;
    background-color: var(--dark-gray);
    min-height: 100vh;
    font-size: 18px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

main {
    padding-top: 80px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

footer {
    width: 100%;
    height: 200px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-grid {
    height: 100%;
    width: 50%;
    display: grid;
    grid-template-columns: 50% 50%;
}

@media (max-width: 480px) {
    .footer-grid {
        height: 100%;
        width: calc(100% - 20px);
        font-size: 8px;
        display: grid;
        grid-template-columns: 50% 50%;
    }
}

.footer-half {
    align-self: center;
    text-align: justify;

    & > h2 {
        margin-top: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
}
