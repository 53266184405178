.videos_container {
    width: 100%;
}

.playlists_container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

@media (max-width: 480px) {
    .playlists_container {
        grid-template-columns: 100%;
    }
}