.link {
    text-decoration: none;
    color: white;
}

.container {
    background-color: rgb(0, 36, 36);
    transition: all 0.2s;
    padding: 4px 12px;
    width: 300px;
    text-align: center;
    border-radius: 2px;
    text-decoration: none;

    &:hover {
        background-color: teal;
    }
}

.title {
    margin: 0;
    font-weight: 400;
}

.info_container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &> svg {
        align-self: flex-end;
        margin-right: 4px;
    }
}