.bannerContainer {
    width: 100%;
    aspect-ratio: 2 / 1;
    overflow: hidden;
    border-radius: 16px;
    display: grid;
}

.bannerTextContainer {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: 100%;
    text-shadow: 0 0 4px black;

    &> h1 {
        font-size: 3vw;
    }
}

.textContainer {
    margin-top: 32px;
}

.technologyContainer {
    display: grid;
    grid-template-columns: repeat(10, 10%);
    margin-top: 20px;
}
