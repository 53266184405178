.player_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 12;
}

.content_container {
    width: 80vw;
    height: 80vh;
    margin-left: 10vw;
    margin-top: 10vh;
    background-color: black;
    display: flex;
    flex-direction: row;
}

.left_container {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #131313;
}

@media (max-width: 480px) {
    .content_container {
        flex-direction: column-reverse;
    }

    .left_container {
        width: 100%;
        height: 50%;
    }
}

.player_title {
    margin: 5px 10px;
    color: white;
}

.close_player_button {
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(10vh + 10px);
    right: calc(10vw + 10px);
    font-weight: bolder;
    cursor: pointer;
    z-index: 13;
    background-color: grey;
    color: white;
    border: none;
    border-radius: 99px;

    &:hover {
        color: var(--text-highlight);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 11;
}

.scroll_container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-color: teal transparent;
    scrollbar-width: thin;
    padding: 0 5px;
}

.video_button {
    width: 100%;
    border: none;
    border-radius: 5px;
    background: none;
    min-height: 36px;
    font-size: 20px;
    text-align: left;
    margin-top: 5px;
    color: white;
    background: linear-gradient(90deg, rgb(26, 26, 26), rgb(42, 42, 42));
    font-weight: lighter;
}

.video_button:hover {
    cursor: pointer;
    background: linear-gradient(90deg, rgb(26, 113, 172), rgb(106, 151, 182));
}

.video_button_active {
    background: teal;
}
