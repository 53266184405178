.page {
    width: 50%;
    text-align: left;
    color: white;
}

@media (max-width: 480px) {
    .page {
        width: calc(100% - 20px);
    }
}