@media (min-width: 767px) {
    #grid_container {
        display: grid;
        grid-template-columns: 380px auto;
    }
    
    #header_container {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 0;
        grid-row-end: 1;
    }
    
    #discord_container {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    
    #discord_description_container {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    
        font-size: 22px;
    }
    
    #youtube_container {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        padding-top: 30px;
    
        text-align: justify;
    }
}