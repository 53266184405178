nav {
    width: 100%;
    height: 80px;
    background: linear-gradient(var(--dark-gray), rgba(19, 19, 19, 0.9));
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 10;
}

nav a {
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    nav {
        padding-left: 12%;
        box-sizing: border-box;
    }

    #logo {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 60px;
        transition: all 0.5s;
    }
}

@media screen and (min-width: 767px) {
    #logo {
        position: absolute;
        left: 60px;
        top: 5px;
        width: 70px;
        transition: all 0.5s;
    }

    #logo:hover {
        left: 55px;
        top: 5px;
        width: 80px;
    }
}
