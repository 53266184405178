.cookie_modal {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: calc(100vw - 60px);
    background-color: maroon;
    color: white;
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px black;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 18px;
    margin: 0;
}

.button {
    border: none;
    font-size: 20px;
    border-radius: 4px;
    padding: 4px;
    box-shadow: 0 2px 4px black;
    width: 150px;

    &:hover {
        cursor: pointer;
    }
}
